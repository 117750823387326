<template>
  <div class="analysis">
    <div class="queryForm">
      <div class="searchBox">
        <van-search
          v-model="searchValue"
          clearable
          placeholder="输入站点名称或站点责任人"
          @search="queryFormOnSubmit"
        />
      </div>
      <van-form @submit="queryFormOnSubmit">
        <van-field
          readonly
          :clickable="!queryForm.bank"
          input-align="right"
          name="picker"
          :value="queryText.deptNo"
          label="运营部"
          right-icon="arrow"
          placeholder="点击选择"
          @click="showDeptPicker = true"
        />
        <van-field
          readonly
          input-align="right"
          name="picker"
          :value="queryText.bank"
          :clickable="!queryForm.deptNo"
          label="银行"
          right-icon="arrow"
          placeholder="点击选择"
          @click="showBankPicker = true"
        />
        <van-field
          readonly
          clickable
          input-align="right"
          name="picker"
          :value="queryText.type"
          right-icon="arrow"
          label="类别"
          placeholder="点击选择"
          @click="showTypePicker = true"
        />
        <van-field
          readonly
          clickable
          input-align="right"
          name="picker"
          :value="queryText.dateType"
          right-icon="arrow"
          label="时间范围"
          placeholder="点击选择"
          @click="showDateTypePicker = true"
        />
        <van-field
          readonly
          clickable
          input-align="right"
          name="picker"
          :value="queryForm.startTime"
          label="开始时间"
          right-icon="arrow"
          placeholder="点击选择"
          v-if="queryForm.dateType === 'other'"
          @click="showStartTimePicker = true"
        />
        <van-field
          readonly
          clickable
          input-align="right"
          name="picker"
          :value="queryForm.endTime"
          label="结束时间"
          right-icon="arrow"
          placeholder="点击选择"
          v-if="queryForm.dateType === 'other'"
          @click="showEndTimePicker = true"
        />
        <van-field
          readonly
          clickable
          input-align="right"
          name="picker"
          :value="queryText.alert"
          label="告警"
          right-icon="arrow"
          placeholder="点击选择"
          @click="showWarmmingPicker = true"
        />
        <div class="btn_row">
          <van-button type="info" :loading="loading" block native-type="submit"
            >查询</van-button
          >
        </div>
        <van-popup v-model="showDeptPicker" position="bottom">
<!--          <van-picker-->
<!--            show-toolbar-->
<!--            :columns="deptList"-->
<!--            @confirm="deptChange"-->
<!--            @cancel="showDeptPicker = false"-->
<!--          />-->
          <van-cascader
                  title="请选择运营部"
                  :options="deptList"
                  @close="showDeptPicker = false"
                  active-color="#3f7c53"
                  :field-names="deptNames"
                  @finish="deptChange"
          />
        </van-popup>
        <van-popup v-model="showBankPicker" position="bottom">
          <!--          <van-picker-->
          <!--            show-toolbar-->
          <!--            :columns="bankList"-->
          <!--            @confirm="-->
          <!--              (value) => pickerOnConfirm('bank', value, 'showBankPicker')-->
          <!--            "-->
          <!--            @cancel="showBankPicker = false"-->
          <!--          />-->
          <van-cascader
            title="请选择银行"
            :options="bankList"
            @close="showBankPicker = false"
            active-color="#3f7c53"
            :field-names="fieldNames"
            @finish="OnFinish"
          />
        </van-popup>
        <van-popup v-model="showTypePicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="typeList"
            @confirm="
              (value) => pickerOnConfirm('type', value, 'showTypePicker')
            "
            @cancel="showTypePicker = false"
          />
        </van-popup>
        <van-popup v-model="showDateTypePicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="dateTypes"
            @confirm="
              (value) =>
                pickerOnConfirm('dateType', value, 'showDateTypePicker')
            "
            @cancel="showDateTypePicker = false"
          />
        </van-popup>
        <van-popup v-model="showStartTimePicker" position="bottom">
          <van-datetime-picker
            type="date"
            @confirm="
              (value) =>
                pickerOnConfirm('startTime', value, 'showStartTimePicker')
            "
            @cancel="showStartTimePicker = false"
          />
        </van-popup>
        <van-popup v-model="showEndTimePicker" position="bottom">
          <van-datetime-picker
            type="date"
            @confirm="
              (value) => pickerOnConfirm('endTime', value, 'showEndTimePicker')
            "
            @cancel="showEndTimePicker = false"
          />
        </van-popup>
        <van-popup v-model="showWarmmingPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="warnList"
            @confirm="
              (value) => pickerOnConfirm('alert', value, 'showWarmmingPicker')
            "
            @cancel="showWarmmingPicker = false"
          />
        </van-popup>
      </van-form>
    </div>
    <div class="sortBox">
      <van-dropdown-menu>
        <van-dropdown-item
          v-model="queryForm.order"
          :options="sorts"
          @change="queryFormOnSubmit"
        />
      </van-dropdown-menu>
    </div>
    <div class="listBox">
      <van-list v-model="loading" :finished="true" finished-text="没有更多了">
        <div class="dataCard" v-for="item in dataList" :key="item.stationNo">
          <div class="cardTitle">{{ item.stationName }}</div>
          <div class="cardAlert" :class="item.alert">
            {{ alerTypes[item.alert] }}
          </div>
          <div class="dataBox">
            <div class="dataItem">
              <div class="dataName">站长</div>
              <div class="dataValue">{{ item.masterName }}</div>
            </div>
            <div class="dataItem">
              <div class="dataName">负责人</div>
              <div class="dataValue">{{ item.marketerName }}</div>
            </div>
            <div class="dataItem">
              <div class="dataName">数据</div>
              <div class="dataValue">{{ item.data }}</div>
            </div>
            <div class="dataItem">
              <div class="dataName">对比</div>
              <div
                class="dataValue"
                :class="item.compare >= 0 ? 'contrastAdd' : 'contrastReduce'"
              >
                {{ item.compare >= 0 ? "+" : "" }}{{ item.compare }}
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import threeLevelMenuOrg from "@/components/threeLevelMenuOrg";
import { getMyBankTree, getStationCompare, getMyDeptTree } from "@/utils/api";
import common from "@/utils/common";
import {
  WARN_ENUM,
  PERFORMANCE_TYPE,
  SORT_ENUM,
  DATE_TYPES,
} from "@/utils/enum";
export default {
  name: "performanceAnalysis",
  components: {
    threeLevelMenuOrg,
  },
  data() {
    return {
      deptList:[],
      bankList: [],
      dateTypes: DATE_TYPES,
      warnList: WARN_ENUM,
      typeList: PERFORMANCE_TYPE,
      sorts: SORT_ENUM,
      searchValue: "",
      columns: ["选项一", "选项二"],
      loading: false,
      queryForm: {
        type: PERFORMANCE_TYPE[0].value,
        deptNo: "",
        markerNameOrStationName: "",
        dateType: "",
        // bank: "",
        endTime: "",
        alert: "",
        startTime: "",
        order: "desc",
        parentNo:'',//总行
        bankNo:'',//分行
        branchNo:'',//支行
      },
      queryText: {
        type: PERFORMANCE_TYPE[0].text,
        deptNo: "",
        markerNameOrStationName: "",
        dateType: "",
        bank: "",
        alert: "",
      },
      dataList: [],
      showDeptPicker: false,
      showBankPicker: false,
      showTypePicker: false,
      showDateTypePicker: false,
      showStartTimePicker: false,
      showEndTimePicker: false,
      showWarmmingPicker: false,
      fieldNames: {
        text: 'bankName',
        value: 'bankCode',
        children: 'child',
      },
      deptNames:{
        text: 'deptName',
        value: 'deptNo',
        children: 'child',
      }
    };
  },
  computed: {
    alerTypes() {
      let alerts = {};
      this.warnList.forEach((item) => {
        alerts[item.value] = item.text;
      });
      return alerts;
    },
  },
  created() {
    this.getDeptList();
    this.setDefaultTimeRange();
  },
  mounted() {
    this.AjaxGetBankList();
    this.queryFormOnSubmit();
  },
  methods: {
    getDeptList(){
      getMyDeptTree({token: common.getItem('wtToken')}).then(res=>{
        if (res.code ===200 && res.data.businessCode === 200) {
            this.deptList = this.getTreeData(res.data.data);
        }
      })
    },
    getTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].child);
        }
      }
      return data;
    },
    setDefaultTimeRange() {
      this.dateTypeOnPick(this.dateTypes[0]);
    },
    AjaxGetBankList() {
      getMyBankTree({}).then((res) => {
        const list = [{ text: "请选择", value: "" }].concat(
                res.data.data
        );
        this.bankList = list;
      });
    },
    deptChange(value){
      this.queryText.bank = '';
      this.queryForm.bankNo = '';
      this.queryForm.parentNo = '';
      this.queryForm.branchNo = '';

      if (this.queryText.bank) {
        this.$notify({
          type: "warning",
          message: "运营部和银行只能选择一个条件",
        });
      } else {
        this.queryText.deptNo = value.selectedOptions.map((option) => option.deptName).join('/');
        this.queryForm.deptNo = value.selectedOptions.length > 0 ? value.selectedOptions[value.selectedOptions.length-1].deptNo:'';
        // this.$set(this.queryForm, 'deptNo', value.value);
        // this.$set(this.queryText, 'deptNo', value.text);
      }
      // this.pickerOnConfirm('deptNo', value, 'showDeptPicker')
    },
    OnFinish(value){
      if (this.queryForm.deptNo) {
        this.$notify({
          type: "warning",
          message: "运营部和银行只能选择一个条件",
        });
        return;
      } else {
        this.queryText.bank = value.selectedOptions.map((option) => option.bankName).join('/');
        this.queryForm.parentNo = value.selectedOptions[0].bankCode;
        this.queryForm.bankNo = value.selectedOptions[1].bankCode;
        this.queryForm.branchNo = value.selectedOptions[2].bankCode;
      }
      // this.pickerOnConfirm('bank', value.selectedOptions, 'showBankPicker');
    },
    queryFormOnSubmit() {
      this.loading = true;
      let params = {
        ...this.queryForm,
        markerNameOrStationName: this.searchValue,
      };
      getStationCompare(params).then((res) => {
        this.loading = false;
        this.dataList = res?.data?.data;
      });
    },
    pickerOnConfirm(key, value, show) {
      if (key === "endTime" || key === "startTime") {
        this.$set(this.queryForm, key, moment(value).format("YYYY-MM-DD"));
      } else if (key === "dateType") {
        this.dateTypeOnPick(value);
      } else if (key === "deptNo") {
        if (this.queryText.bank) {
          this.$notify({
            type: "warning",
            message: "运营部和银行只能选择一个条件",
          });
        } else {
          this.$set(this.queryForm, key, value.value);
          this.$set(this.queryText, key, value.text);
        }
      } else if (key === "bank") {
        console.log('finish:',value);
        return;
        if (this.queryForm.deptNo) {
          this.$notify({
            type: "warning",
            message: "运营部和银行只能选择一个条件",
          });
        } else {
          this.$set(this.queryForm, key, value.value);
          this.$set(this.queryText, key, value.text);
        }
      } else {
        this.$set(this.queryForm, key, value.value);
        this.$set(this.queryText, key, value.text);
      }
      this[show] = false;
    },
    dateTypeOnPick(value) {
      this.$set(this.queryForm, "dateType", value.value);
      this.$set(this.queryText, "dateType", value.text);
      let weekOfday = parseInt(moment().format("d"));
      switch (value.value) {
        case "lastWeek":
          this.$set(
            this.queryForm,
            "startTime",
            moment()
              .subtract(weekOfday + 6, "days")
              .format("YYYY-MM-DD")
          );
          this.$set(
            this.queryForm,
            "endTime",
            moment().subtract(weekOfday, "days").format("YYYY-MM-DD")
          );
          break;
        case "lastMonth":
          this.$set(
            this.queryForm,
            "startTime",
            moment()
              .month(moment().month() - 1)
              .startOf("month")
              .format("YYYY-MM-DD")
          );
          this.$set(
            this.queryForm,
            "endTime",
            moment()
              .month(moment().month() - 1)
              .endOf("month")
              .format("YYYY-MM-DD")
          );
          break;
        case "lastSeason":
          this.$set(
            this.queryForm,
            "startTime",
            moment()
              .quarter(moment().quarter() - 1)
              .startOf("quarter")
              .format("YYYY-MM-DD")
          );
          this.$set(
            this.queryForm,
            "endTime",
            moment()
              .quarter(moment().quarter() - 1)
              .endOf("quarter")
              .format("YYYY-MM-DD")
          );
          break;
        case "lastYear":
          this.$set(
            this.queryForm,
            "startTime",
            moment()
              .year(moment().year() - 1)
              .startOf("year")
              .format("YYYY-MM-DD")
          );
          this.$set(
            this.queryForm,
            "endTime",
            moment()
              .year(moment().year() - 1)
              .endOf("year")
              .format("YYYY-MM-DD")
          );
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="less">
.analysis {
  background: #f5f7fa;
  min-height: 100%;
  .queryForm {
    background: #ffffff;
    .btn_row {
      padding: 0.16rem 0.3rem;
    }
  }
  .sortBox {
    height: 0.72rem;
    font-size: 0.26rem;
    color: #595d62;
    background: #f5f7fa;
    .van-dropdown-menu {
      height: 100%;
    }
    .van-dropdown-menu__bar {
      height: 100%;
      background: transparent;
      box-shadow: none;
      .van-dropdown-menu__item {
        justify-content: flex-start;
        padding: 0 0.15rem;
        .van-dropdown-menu__title {
          font-size: 0.26rem;
          color: #595d62;
        }
      }
    }
  }
  .listBox {
    background: #ffffff;
    .dataCard {
      padding: 0.3rem;
      position: relative;
      border-bottom: solid 1px #f5f7fa;
      .cardAlert {
        position: absolute;
        right: 0;
        top: 0.3rem;
        height: 0.36rem;
        border-top-left-radius: 0.18rem;
        border-bottom-left-radius: 0.18rem;
        line-height: 0.36rem;
        font-size: 0.24rem;
        color: #ffffff;
        width: 1.08rem;
        padding-left: 0.08rem;
        text-align: center;
        &.noAlert {
          background: #32b698;
        }
        &.redAlert {
          background: #f23d3d;
        }
        &.yellowAlert {
          background: #f08519;
        }
        &.blueAlert {
          background: #4873d3;
        }
        &.missingAlert {
          background: #a3a5a8;
        }
      }
      .cardTitle {
        font-size: 0.32rem;
        color: #191f25;
        font-weight: bold;
        margin-bottom: 0.3rem;
      }
      .dataBox {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        .dataItem {
          width: 23%;
          .dataName {
            font-size: 0.24rem;
            color: #7e8185;
            margin-bottom: 0.2rem;
          }
          .dataValue {
            font-size: 0.28rem;
            color: #191f25;
            &.contrastAdd {
              color: #32b698;
              font-size: 0.34rem;
            }
            &.contrastReduce {
              color: #f23d3d;
              font-size: 0.34rem;
            }
          }
        }
      }
    }
  }
}
</style>
